// import "./App.css";
import Main from "./Components/Main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Registration from "./Components/Registration";
import About from "./Components/About.jsx";

function App() {
  return (
    <div style={{backgroundColor:"white",flex:1, justifyContent:"center"}}>
      {/* <span className="borderSpan FillAll" id="borderSpan" />
      <div className="LoadingDiv" id="LoadingDiv">
        <h1>Loading</h1>
        <div id="icon3" />
      </div>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/registration">
              <Registration />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </div>
      </Router> */}
    </div>
  );
}

export default App;
