import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Stage1 from "./Stages/Stage1";
import Stage2 from "./Stages/Stage2";
import Stage3 from "./Stages/Stage3";
import Stage4 from "./Stages/Stage4";
import Stage5 from "./Stages/Stage5";
import Stage6 from "./Stages/Stage6";
import Stage7 from "./Stages/Stage7";
import Stage8 from "./Stages/Stage8";
import Stage9 from "./Stages/Stage9";
import Stage10 from "./Stages/Stage10";
import Stage11 from "./Stages/Stage11";
import logo from "../logo2.png";
import Success from "./Success";
import "./reg.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
const addRegisterer = gql`
  mutation createRegistered($input: createRegisteredInput!) {
    createRegistered(input: $input) {
      registered {
        CompanyName
      }
    }
  }
`;
export default function Registration() {
  const [countryes, setcountry] = useState([]);

  const [addRegister, { data, error }] = useMutation(addRegisterer);

  useEffect(() => {
    fetch("https://restcountries.eu/rest/v2/all")
      .then((res) => res.json())
      .then((result) => {
        setcountry(result);
      });
  }, []);
  const year = new Date().getFullYear();
  const years = Array.from(new Array(80), (val, index) => year - index);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    if (data.CompanyName === undefined) {
      window.location.href = "/registration/1";
      alert("YOU MUST FILL ALL FIELDS");
      return "";
    }
    await addRegister({
      variables: { input: { data: data } },
    });
  };

  const WatchProductTypes = watch("TypesofProducts");
  const WatchPatentfiled = watch("Patentfiled");

  if (data) {
    const loadingDiv = document.getElementById("LoadingDiv");
    const icon = document.getElementById("icon2");
    const loading = document.getElementById("icon3");
    loadingDiv.style.display = "none";
    icon.style.display = "block";
    loading.style.display = "none";

    return (
      <>
        <div className="logo">
          <img src={logo} alt="dd" />
        </div>
        <div className="container">
          <div className="flexGrow" id="flexGROW">
            <div id="icon2" />
            <Grid container spacing={3}>
              <div className="stage MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-12">
                <Success />
              </div>
            </Grid>
          </div>
        </div>
      </>
    );
  }

  if (error) {
    console.log(error);
    window.location.href = "/registration/1";
    alert("Error Please Fill Fields.");
    return "";
  }

  return (
    <>
      <div className="logo">
        <img src={logo} alt="dd" />
      </div>
      <div className="container">
        <div className="flexGrow" id="flexGROW">
          <div id="icon2" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <div className="stage MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-12">
                <Router>
                  <Switch>
                    <Route path="/registration/1">
                      <Stage1
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/2">
                      <Stage2
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/3">
                      <Stage3
                        register={register}
                        errors={errors}
                        countryes={countryes}
                        watch={watch}
                        setValue={setValue}
                      />
                    </Route>
                    <Route path="/registration/4">
                      <Stage4
                        register={register}
                        errors={errors}
                        WatchProductTypes={WatchProductTypes}
                        years={years}
                        watch={watch}
                        setValue={setValue}
                      />
                    </Route>
                    <Route path="/registration/5">
                      <Stage5
                        register={register}
                        errors={errors}
                        WatchPatentfiled={WatchPatentfiled}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/6">
                      <Stage6
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/7">
                      <Stage7
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/8">
                      <Stage8
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/9">
                      <Stage9
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/10">
                      <Stage10
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/11">
                      <Stage11
                        register={register}
                        errors={errors}
                        years={years}
                        watch={watch}
                      />
                    </Route>
                    <Route path="/registration/success">
                      <Success />
                    </Route>
                  </Switch>
                </Router>
              </div>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
}
