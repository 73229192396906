import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://thecrossroadonline.herokuapp.com/graphql",
  cache: new InMemoryCache(),
  onError: (e) => {
    console.log(e);
  },
});
export default client;
